import React, { useEffect } from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import AbtBanner from '../MenAbout/AbtBanner'
import TextFlow from '../MenAbout/TextFlow'
import Aos from 'aos'



// import Dish01 from '../assets/images/dish.png'
import Icon10 from '../../assets/images/outj_icon1.png'
import Icon02 from '../../assets/images/outj_icon2.png'
import Icon03 from '../../assets/images/outj_icon3.png'


import chefimg01 from '../../assets/images/ar.webp'




function Main() {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);



  function Journey() {
    return (
      <>
        <section className="row_am our_journey bg_white_textcher">
          <div className="side_shape position-absolute" data-aos="fade-in" data-aos-duration={1500} >
            {/* <img src={Dish01} alt="img" /> */}
          </div>
          <div className="container">
            <div className="row_am">
              <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
                <span className="sub_text">Why we are best</span>
                <h2>
              
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                    <div className="icon">
                      <img src={Icon03} alt="img" />
                    </div>
                    <h3>Luxurious Dining Spaces</h3>
                    <p>
                    Our restaurants, boast elegant and inviting interiors, providing the perfect ambiance for enjoying exquisite meals with friends, family, or colleagues.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                    <div className="icon">
                      <img src={Icon02} alt="img" />
                    </div>
                    <h3>Private Event Spaces</h3>
                    <p>
                    Whether you're hosting a corporate gathering, wedding celebration, or social event, Armaya offer versatile and enchanting venues to accommodate your needs.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                    <div className="icon">
                      <img src={Icon10} alt="img" />
                    </div>
                    <h3>Ample Parking</h3>
                    <p>
                    Convenient parking facilities are available to ensure hassle-free access for you and your guests.Generous Parking Provisions for Seamless Arrival and Departure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  function Chef3() {
    return (
      <>
        <section className="chef_section row_am bg_white_textcher">
          <div className="side_shape position-absolute">
            {/* <img src={chefimg} alt="img" /> */}
          </div>
          <div className="container">
            <div className="row my-2 my-sm-4">
              <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500} >
                <div className="chef_img">
                  <img className="radius_img img-fluid" src={chefimg01} alt="img" />
                </div>
                <h3 className="chef_name">
                  <span>ARMAYA</span> :
Unforgettable Moments in Gandhidham
                </h3>
              </div>
              <div className="col-md-6">
                <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500} >
                 
                  <div className="section_title">
                    <span className="sub_text"> Elevate Your Culinary Journey</span>
                    <h2 className="mb-3">
                    Explore Two Unique Dining Experiences
                    </h2>
                    <p>
                    Welcome to Armaya, a culinary oasis crafted by the Ten11 group. Dive into a world of flavors at Larissa, where our global menu and in-house bakery promise to tantalize your taste buds. Craving traditional Indian cuisine? Riyasat beckons with its rich and vibrant dishes, including our renowned Gujarati thali.
<br/>
<br/>
But Armaya offers more than just exceptional dining. Our Starlight Ballroom sets the stage for unforgettable events, while the Love Courtyard provides a picturesque backdrop for outdoor gatherings. Join us and create memories that last a lifetime amidst the warmth of hospitality and the joy of togetherness.
                    </p>
                  </div>
                  {/* <Link to="/ourchef" className="btn btn_text">
                    contact us <i className="icofont-long-arrow-right" />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }



  return (
    <div className='page_wrapper'>
      <Header />
      <AbtBanner />
      <TextFlow />
      <Chef3/>
      <Journey />
      <Footer />
    </div>
  )
}

export default Main