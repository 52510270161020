import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate, formatTime } from '../../utils';

function FooterReservation({setytShow }) {
  const name = useRef(null);
  const person = useRef(null);
  const mobileNo = useRef(null);
  const message = useRef(null);
  const date = useRef(null);
  const time = useRef(null);
  const restaurant = useRef(null);
  
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log('FORM SUBMISSION PARAMS -----------> ',{
      name: name,
      mobileNo: mobileNo,
      message: message,
      date: date,
      time: time,
      restaurant: restaurant.current.value,
      formType: 'RESERVATION'

    });
    const res = await axios.post("/.netlify/functions/submitForm", {
      name: name.current.value,
      mobileNo: mobileNo.current.value,
      person: person.current.value,
      message: message.current.value,
      date: date.current.value,
      time: time.current.value,
      restaurant: restaurant.current.value,
      formType: 'RESERVATION'

    });
    const response = await res.data;
    setResponse(response);
    setLoading(false);
  };
  
  return (<div className="modal fade booking_model booking_model-footer show px-3" id="bookingForm_model" tabIndex={-1} aria-labelledby="bookingForm_modelTitle" style={{ display: "block" }} aria-modal="true" role="dialog" >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header text-center border-0 d-block p-0">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setytShow(false)}>
            <span aria-hidden="true">
              <i className="icofont-close-line" />
            </span>
          </button>
          <h2 className="mb-2">Online Reservation</h2>
          <p>
            Booking request <a href="tel:+919512341011">+91-95123-41011</a> or fill
            out the order form
          </p>
        </div>
        <div className="modal-body">
          <div className="booking_form">
            <form onSubmit={()=>{}}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" ref={name} placeholder="Your Name" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" ref={mobileNo} placeholder="Phone Number" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <span className="icon">
                      <i className="icofont-user-alt-7" />
                    </span>
                    <input type="number" style={{ paddingLeft: '35px' }} ref={person} defaultValue={2} className="form-control has_icon" placeholder="2 Person" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <span className="icon">
                      <i className="icofont-calendar" />
                    </span>
                    <input type="date" style={{ paddingLeft: '35px' }} defaultValue={formatDate(new Date())} ref={date} className="form-control  flex-row-reverse" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <span className="icon">
                      <i className="icofont-clock-time" />
                    </span>
                    <input type="time" style={{ paddingLeft: '35px' }} defaultValue={formatTime(new Date())} ref={time} className="form-control has_icon  flex-row-reverse"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <span className="icon">
                      <i className="icofont-restaurant" />
                    </span>
                    <select style={{ paddingLeft: '35px' }} defaultValue={'LARISSA'} ref={restaurant} className="form-control has_icon  flex-row-reverse" >
                      <option value={'LARISSA'} >Larissa</option>
                      <option value={'RIYASAT'} >Riyasat</option>
                      <option value={'CELEBRATION_COURTYARD'} >Celebration Courtyard</option>
                      <option value={'STARLIGHT_BALLROOM'} >Starlight Ballroom</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      ref={message}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <button className="btn btn_primary w-100" disabled={loading} onClick={handleSubmit}>book a table</button>
                </div>
                {response && <div className="px-2 py-2 mt-5 col-md-12" style={{ background: "var(--primary-red)", borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between'
 }}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>)
}
function Main() {

  const [ytshow, setytShow] = useState(false);
  const email = useRef(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = async () => {
    setLoading(true);
    const apiResponse = await axios.post("/.netlify/functions/submitForm", { 
      email: email.current.value,
      formType: "SUBSCRIBE"
    });
    console.log(apiResponse);
    setResponse(apiResponse.data);
    setLoading(false);
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="foote_widget contact_info">
            <div className="row py_50">
              <div className="col-md-7 direction_block">
                <p>
                Plot No. 18, 19, 34 & 35, Ward 5B,
                Adipur, Gandhidham, Kutch, Gujarat 370205 <br />
                  Booking : <Link to="tel:+919512341011">+91-95123-41011</Link>
                </p>
                <Link to="/contact" className="btn_text">
                  get direction <i className="icofont-long-arrow-right" />
                </Link>
              </div>
              <div className="col-md-5 text-md-right timing_block">
                <p>
                  Monday - Sunday : 11:30AM - 11:00PM <br />
                
                </p>
                <Link to="#" className="btn btn_primary mt-3" id="bookingForm_modelTitle" data-toggle="modal" data-target="#bookingForm_model" onClick={() => setytShow(true)}>
                  book a table
                </Link>
              </div>
            </div>
          </div>
          <div className="foote_widget newsletter_block">
            <div className="row py_50">
              <div className="col-md-7 offer_block">
                <h2>Get News &amp; Offers</h2>
                <p>
                  Subscribe us <span></span>
                </p>
                <form onSubmit={(e)=>{e.preventDefault()}} >
                  <div className="form-group">
                    <span className="icon position-absolute">
                      <i className="icofont-envelope-open" />
                    </span>
                    <input type="text" placeholder="Your email" className="form-control" ref={email} required="" />
                    <button className="btn btn_primary" onClick={handleSubmit} disabled={loading}>subscribe now</button>
                  </div>
                  {response && <div className="px-2 py-2" style={{ background: "var(--primary-red)", borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between'}}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
                </form>
              </div>
              <div className="col-md-5">
                <ul className="social_media">
                  {/* <li>
                    <a href="https://www.facebook.com/" target='_blank'>Facebook</a>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/armaya_1011" rel="noreferrer" target='_blank'>Instagram</a>
                  </li>
                  {/* <li>
                    <a href="https://twitter.com/" target='_blank'>Twitter</a>
                  </li> */}
                  {/* <li>
                    <a href="https://www.youtube.com/" target='_blank'>Youtube</a>
                  </li> */}






                </ul>
              </div>
            </div>
          </div>
          <div className="foote_widget menu_links">
            <div className="row">
              <div className="col-md-12">
                <ul className="links d-flex justify-content-center">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/larissa">larissa</Link>
                  </li>
                  {/* {/* {/* <li>
                    <Link to="/menulist2">Menu 2</Link>
                  </li>} */}
                  <li>
                    <Link to="/riyasat">riyasat</Link>
                  </li>
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/celebration-courtyard">Celebration Courtyard</Link>
                  </li>
                  <li>
                    <Link to="/starlight-ballroom">Starlight Ballroom</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 copy_right text-center">
            
                <p>© 2024 Armaya by Ten 11 Group. All Rights Reserved |{" "}
                Made with ❤️ by <Link to="https://www.civoncetech.com/" target="_blank" style={{ textDecoration: 'underline', color: '#F57C00' }}>Civonce Technologies</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-rounded-up" />
          </span>
        </div>
      </footer>
      {ytshow && <FooterReservation setytShow={setytShow} />}
    </>
  )
}

export default Main;