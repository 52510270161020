import React from 'react'
import { Link } from 'react-router-dom'
// import Img01 from '../../assets/images/veg_side.png'
import Img02 from '../../assets/images/private_event_01.webp'
// import Img03 from '../../assets/images/private_icon.png'
import Img04 from '../../assets/images/private_event_02.webp'

function PrivateSection() {
  return (
    <>
      <section className="row_am privet_event_section bg_white_textcher position-relative">
        <div className="sider_shape" data-aos="fade-in" data-aos-duration={1500}>
          {/* <img src={Img01} alt="img" /> */}
        </div>
        <div className="container">
          <div className="row py_50">
            <div className="col-md-6">
              <div className="privet_text" data-aos="fade-up" data-aos-duration={1500} >
                <div className="section_title">
                  <span className="sub_text">Private events</span>
                  <h2>
                    we provide private event with enjoyable dining experience &amp;
                    traditional cuisine
                  </h2>
                </div>
                <img className="d-block ml-auto" src={Img02} alt="img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="privet_info position-relative">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <span className="side_icon">
                    {/* <img src={Img03} alt="img" /> */}
                  </span>
                  <img className="img-fluid radius_img" src={Img04} alt="img" />
                </div>
                <p data-aos="fade-up" data-aos-duration={1500}>
                Immerse yourself in the charm of ARMAYA's private events, where every occasion is infused with the essence of our rich culinary traditions. Our dedicated team ensures each gathering is a memorable experience, offering a perfect blend of delightful dining and authentic flavors. From intimate celebrations to grand affairs, ARMAYA sets the stage for joyous occasions filled with warmth, hospitality, and the unmistakable taste of tradition.
                </p>
                <Link to="/reservation" className="btn btn_primary" data-aos="fade-up" data-aos-duration={1500} >
                  BOOK EVENT NOW <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivateSection