import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import photo1 from './assets/images/photo_1.jpg';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
      <App />
      <FloatingWhatsApp key={'49538538953'} accountName="Armaya" allowClickAway phoneNumber="919512341011" statusMessage="Ten11 Group" avatar={photo1} chatMessage="🚀 Welcome to Armaya by Ten11 Group. How can we help you?" className='whatsapp_wrapper' />
    </>

  </React.StrictMode>
);