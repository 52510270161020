import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Ctn01 from '../../assets/images/abtbanshape.png'
import Ctn03 from '../../assets/images/contact_icon.png'
import Ctn04 from '../../assets/images/contact.webp'
import axios from 'axios'

function ContactUS() {
  const name = useRef(null);
  const mobileNo = useRef(null);
  const email = useRef(null);
  const message = useRef(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitForm = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      const res = await axios.post("/.netlify/functions/submitForm", {
        name: name.current.value,
        mobileNo: mobileNo.current.value,
        email: email.current.value,
        message: message.current.value,
        formType: 'CONTACT'
      });
      const response = await res.data;
      setResponse(response);
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      setResponse('An unexpected Error Occurred');
    }
  };
  return (
    <>
      <section className="contact_us_section">
        <div className="right_shape position-absolute">
          <img src={Ctn01} alt="img" />
        </div>
        <div className="left_shape position-absolute">
          {/* <img src={Ctn02} alt="img" /> */}
        </div>
        <div className="container">
          <div className="section_title text-center">
            <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
              <img src={Ctn03} alt="img" />
            </span>
            <span className="sub_text mb-1" data-aos="fade-up" data-aos-duration={1500} >
              Contact us
            </span>
            <h2 data-aos="fade-up" data-aos-duration={1500}>
              Get in touch with us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="radius_img" src={Ctn04} alt="img" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact_box">
                <ul className="contact_listing" data-aos="fade-up" data-aos-duration={1500} >
                  <li>
                    <p className="sub_title">Call us</p>
                    <div className="cnt_block">
                      <Link to="tel:+919512341011">+91-95123-41011</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+919512341011">+91-95123-41011</Link>
                      <span>
                        <i className="icofont-whatsapp" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Email us</p>
                    <div className="cnt_block">
                      <Link to="mailto:">info@armayabyten11group.com</Link>
                      <span>
                        <i className="icofont-envelope-open" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Find us</p>
                    <div className="cnt_block">
                      <p>Plot No. 18, 19, 34 & 35, Ward No.5B, <br />
                Adipur, Gandhidham, Kutch, Gujarat 370205 <br /></p>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                  </li>
                </ul>
                <form data-aos="fade-up" data-aos-duration={1500}>
                  <h3>Say hi, Don’t be shy!</h3>
                  <div className="form-group">
                    <input type="text" ref={name} className="form-control" placeholder="Your Name" />
                  </div>
                  <div className="form-group">
                    <input type="text" ref={email} className="form-control" placeholder="Email" />
                  </div>
                  <div className="form-group">
                    <input type="text" ref={mobileNo} className="form-control" placeholder="Phone Number" />
                  </div>
                  <div className="form-group">
                    <textarea className="form-control" ref={message} placeholder="Message" defaultValue={""} />
                  </div>
                  <div className="form-group text-right">
                    <button type="submit" onClick={submitForm} disabled={loading} className="btn btn_primary">
                      SUBMIT
                    </button>
                    {response && <div className="px-2 py-2 mt-5" style={{ background: "var(--primary-red)", borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between'
 }}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="map" data-aos="fade-up" data-aos-duration={1500}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16720.89059683883!2d70.09227889341946!3d23.06916612608721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b9ddca5abc8b%3A0x6a644c6e325e47ca!2sARMAYA%20by%20Ten11%20Group!5e0!3m2!1sen!2sin!4v1710602714488!5m2!1sen!2sin"
              height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUS
