import React from 'react'
import { Route, Routes,  } from 'react-router-dom';
import Home from '../components/Home/Main'
import About from '../components/About/Main'
import Resrv2 from '../components/Resrv2/Main';
import Contact from '../components/Contact/Main'
import Gallery from '../components/Gallery/Main'
import Larissa from '../components/larissa';
import Riyasat from '../components/riyasat';
import StarlightBallroom from '../components/starlight-ballroom';
import CelebrationCourtyard from '../components/celebration-courtyard';

function Index() {
    
  return (
    <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/reservation" element={<Resrv2 />} />
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/larissa" element={<Larissa />} />
          <Route path="/riyasat" element={<Riyasat />} />
          <Route path="/celebration-courtyard" element={<CelebrationCourtyard />} />
          <Route path="/starlight-ballroom" element={<StarlightBallroom />} />

        </Routes>
    </>
  )
}

export default Index