import React from 'react'
import Img03 from '../../assets/images/b_img_01.webp'
import Img05 from '../../assets/images/ban_02_shape.png'
import Img06 from '../../assets/images/b_img_02.webp'
import Img07 from '../../assets/images/since_round.png'
import Img08 from '../../assets/images/drop_arrow.svg'
import Img09 from '../../assets/images/b_img_03.webp'


function Banner() {
  return (
    <>
      <section className="banner_section">
        <div className="social_media_side side_fixer"> 
          <ul>
          <li>
            </li>
            <li>
              <a href="https://www.instagram.com/armaya_1011" target='_blank'  rel="noreferrer">Instagram</a>
            </li>
            <li>
            </li>
          </ul>
        </div>
        <div className="timing_side side_fixer">
          <ul>
            <li>
              <span>Mon - Sun : 11:30AM - 11PM </span>
            </li>
            <li>
              <span> Mon - Sun : 11:30AM - 11PM</span>
            </li>
          </ul>
        </div>
        <div className="banner_side_shape">
          <div className="left_shape">
            {/* <img src={Img01} alt="img" /> */}
          </div>
          <div className="right_shape">
            {/* <img src={Img02} alt="img" /> */}
          </div>
        </div>
        <div className="container">
          <div className="banner_inner">
            <h1 data-aos="fade-up" data-aos-duration={1500}>
            Where Every Flavor Finds<br /> Its Perfect Ambiance
            </h1>
            <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
            Elevating Dining in the Heart of Kutch: Explore ARMAYA's Culinary Delights
            </p>
            <div className="banner_images">
              <div className="img" data-speed={100}>
                <img className="radius_img" src={Img03} alt="img" />
                <div className="bt_curve_text">
                  {/* <img src={Img04} alt="img" /> */}
                </div>
              </div>
              <div className="img" data-speed={100}>
                <img src={Img05} className="side_shape" alt="img" />
                <img className="radius_img" src={Img06} alt="img" />
                <div className="banner_badge">
                  <div className="since">
                    <p>Explore</p>
                    <span>Delights</span>
                  </div>
                  <div className="round_anim">
                    <img src={Img07} alt="img" />
                  </div>
                </div>
                <div className="go_down">
                  <img src={Img08} alt="img" />
                </div>
              </div>
              <div className="img" data-speed={100}>
                <img className="radius_img" src={Img09} alt="img" />
                {/* <img className="badge_shape" src={Img10} alt="img" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner