import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main'
import AboutImg from '../assets/images/abtbanshape.png'
import Icon01 from '../assets/images/title_icon.png'
import abt01 from '../assets/images/abtban_01_cb.png'
import abt02 from '../assets/images/abtban_02_cb.png'
import abt03 from '../assets/images/abtban_03_cb.png'
import abt04 from '../assets/images/abtban_04_cb.png'
import abt05 from '../assets/images/abtban_05_cb.png'


import Icon10 from '../assets/images/outj_icon1.png'
import Icon02 from '../assets/images/outj_icon2.png'
import Icon03 from '../assets/images/outj_icon3.png'

import Reservation from '../../src/components/MainReserv/ReservTwo';

import chefimg01 from '../assets/images/master_chef.webp'











import Aos from 'aos'
import TextFlow from '../components/MenAbout/TextFlow'
import { Link } from 'react-router-dom';

function StarlightBallroom() {

    useEffect(() => {
       window.scrollTo(0,0);
    }, []);

    return (
        <div className='page_wrapper'>
          <Header />
          <>
      <section className="abt_banner">
        <div className="right_shape position-absolute">
          <img src={AboutImg} alt="img" />
        </div>
        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
          Starlight Ballroom <br/> Banquet Hall{" "}
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
          Experience the Glamour Where Every Occasion Shines with Brilliance
          </h2>

          
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          
          Dance under the Stars at Starlight Ballroom Where Every Step Tells a Story
          
          
          </p>
        </div>
        <div className="abt_img">
          <div className="img abt-img-res" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt01} alt="img" />
          </div>
          <div className="img abt-img-res" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt02} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={abt03} alt="img" />
          </div>
          <div className="img abt-img-res" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt04} alt="img" />
          </div>
          <div className="img abt-img-res" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt05} alt="img" />
          </div>
        </div>
      </section>
    </>
          <TextFlow />

          <section className="row_am offer_menu_section">
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="sub_text">Event Packages</span>
            <h2>Tailored Experiences for Every Celebration</h2>
          </div>
          </div>
          </section>


          <Menu />
          <Chef />
          <Chef2 />
          <Chef3 />
          <Journey />
          <Reservation />
          <Footer />

          </div>
  )
}

function Chef() {
  return (
    <>
      <section className="chef_section row_am bg_white_textcher">
        <div className="side_shape position-absolute">
          {/* <img src={chefimg} alt="img" /> */}
        </div>
        <div className="container">
          <div className="row my-2 my-sm-4">
            <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="chef_img">
                <img className="radius_img img-fluid" src={chefimg01} alt="img" />
              </div>
              <h3 className="chef_name">
                <span>Armaya offers</span> Wedding Packages
              </h3>
            </div>
            <div className="col-md-6">
              <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500} >
             
                <div className="section_title">
                  <span className="sub_text"> Celebrate your special day</span>
                  <h2 className="mb-3">
                  Our expert team at ARMAYA  <br /> offers customizable wedding packages
                  </h2>
                  <p>
                  At Armaya, we understand the importance of your wedding day and strive to make it truly unforgettable. We are dedicated to exceeding your expectations and creating a celebration that reflects your love story.
                  </p>
                </div>
                <Link to="/contact" className="btn btn_text">
                  contact us <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function Chef2() {
  return (
    <>
      <section className="chef_section row_am bg_white_textcher">
        <div className="side_shape position-absolute">
          {/* <img src={chefimg} alt="img" /> */}
        </div>
        <div className="container">
          <div className="row my-2 my-sm-4">
            <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="chef_img">
                <img className="radius_img img-fluid" src={chefimg01} alt="img" />
              </div>
              <h3 className="chef_name">
                <span>Armaya offers</span> Corporate Packages
              </h3>
            </div>
            <div className="col-md-6">
              <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500} >
               
                <div className="section_title">
                  <span className="sub_text">Elevate Your Corporate Events</span>
                  <h2 className="mb-3">
                  Our dedicated team at ARMAYA<br /> offers customizable packages for corporate events
                  </h2>
                  <p>
                  At Armaya, we understand the significance of your corporate gatherings and aim to make them extraordinary. We are committed to surpassing your expectations and crafting an event that aligns with your company's vision and goals.
                  </p>
                </div>
                <Link to="/contact" className="btn btn_text">
                  contact us <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function Chef3() {
  return (
    <>
      <section className="chef_section row_am bg_white_textcher">
        <div className="side_shape position-absolute">
          {/* <img src={chefimg} alt="img" /> */}
        </div>
        <div className="container">
          <div className="row my-2 my-sm-4">
            <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="chef_img">
                <img className="radius_img img-fluid" src={chefimg01} alt="img" />
              </div>
              <h3 className="chef_name">
                <span>Armaya offers</span> Social Gatherings Packages
              </h3>
            </div>
            <div className="col-md-6">
              <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500} >
               
                <div className="section_title">
                  <span className="sub_text">Elevate Your Social Gatherings</span>
                  <h2 className="mb-3">
                  Our dedicated team at ARMAYA  <br /> offers customizable packages for social gatherings
                  </h2>
                  <p>
                  At Armaya, we understand the importance of your social events and strive to make them memorable. We are committed to exceeding your expectations and creating an atmosphere that fosters joy, connection, and unforgettable moments with your loved ones.
                  </p>
                </div>
                <Link to="/contact" className="btn btn_text">
                  contact us <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}





function Journey() {
    return (
      <>
        <section className="row_am our_journey bg_white_textcher">
          <div className="side_shape position-absolute" data-aos="fade-in" data-aos-duration={1500} >
            {/* <img src={Dish01} alt="img" /> */}
          </div>
          <div className="container">
            <div className="row_am">
              <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
                <span className="sub_text">Why we are best</span>
                <h2>
              
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                    <div className="icon">
                      <img src={Icon03} alt="img" />
                    </div>
                    <h3>Luxurious Dining Spaces</h3>
                    <p>
                    Our restaurants, boast elegant and inviting interiors, providing the perfect ambiance for enjoying exquisite meals with friends, family, or colleagues.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                    <div className="icon">
                      <img src={Icon02} alt="img" />
                    </div>
                    <h3>Private Event Spaces</h3>
                    <p>
                    Whether you're hosting a corporate gathering, wedding celebration, or social event, Armaya offer versatile and enchanting venues to accommodate your needs.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                    <div className="icon">
                      <img src={Icon10} alt="img" />
                    </div>
                    <h3>Ample Parking</h3>
                    <p>
                    Convenient parking facilities are available to ensure hassle-free access for you and your guests.Generous Parking Provisions for Seamless Arrival and Departure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

function Menu() {

    
  
  
    const [toggler, setToggler] = useState(false);
  
    const [activeImage, setActiveImage] = useState(1)
  
  
  
    return (
      <>
        
        
      </>
    )
  }

export default StarlightBallroom;