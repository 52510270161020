import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import reviewimg from '../../assets/images/review_01.svg'
import reviewimg3 from '../../assets/images/review_03.svg'
import videoplay from '../../assets/images/video_play.png'
import review_250 from '../../assets/images/review_250.webp'
import review_251 from '../../assets/images/review_251.webp'

function Review() {

  const [video, setvideo] = useState(false);

  return (
    <>
      <section className="row_am review_section">
        <div className="container">
          <div className="title">
            <h2 className="review_text">reviews</h2>
          </div>
          <div className="review_list">
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={reviewimg} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                This place was fantastic! The ambiance was charming, and the service was on point. Plus, the food is Amazing.
                </p>
                <h3 className="name">- Bhavya Kriplani</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            {/* <div className="review_box with_video">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="video_box" onClick={() => setvideo(true)}>
                <Link to="#" onClick={() => setvideo(true)} className="popup-youtube play-button" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" >
                  <img src={videoplay} alt="img" />
                </Link>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div> */}
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={reviewimg3} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                Larissa is a delightful
addition to our town's dining scene. The staff exhibited exceptional
welcoming atmosphere.
                </p>
                <h3 className="name">- Nishipal Rana</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={review_250} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                I dined at Larissa. Their global cuisine
restaurant. The ambience is great, well
eloquently done. The staff is friendly and has
been briefed of preparations.
                </p>
                <h3 className="name">- Jincy Shah</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            {/* <div className="review_box with_video">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="video_box">
                <Link to="#"  onClick={() => setvideo(true)} className="popup-youtube play-button" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" >
                  <img src={videoplay} alt="img" />
                </Link>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div> */}
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={review_251} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                The
authentic multi cuisine offerings showcase a
rich tapestry of flavors, satisfying even the
most discerning taste buds and catered to
every palate.      </p>
                <h3 className="name">- Bhumika Thakkar</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
          </div>
        </div>
      </section>

      {video &&
        <div className="modal fade youtube-video show" id="myModal" tabIndex={-1} role="dialog" style={{ paddingRight: 17, display: "block" }} aria-modal="true" >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setvideo(false)}>
              <button id="close-video" type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setvideo(false)}>
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/NC9KlaxtfLg?autoplay=1&mute=1" />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Review