import { Link } from 'react-router-dom'
import React, { useState } from "react";
import Slider from 'react-slick';
import BG from '../../assets/images/video_poster_home.png'
import icon01 from '../../assets/images/title_icon.png'
import LarissaLogo from '../../assets/images/larissa.svg';
import videoimg from '../../assets/images/video_text.png'
import playimg from '../../assets/images/video_play.png'
import Riyasatlogo from '../../assets/images/riyasatlogo.svg'
import celebrationcourtyardlogo from '../../assets/images/celebrationcourtyardlogo.svg'
import starlightballroomlogo from '../../assets/images/starlightballroomlogo.svg'

const DeliciousMenu = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

function Deliciousmenu() {

  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <section className="row_am delicous_menu">
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="icon">
              <img src={icon01} alt="img" />
            </span>
            <span className="sub_text">Best Restaurant in Gandhidham </span>
            <h2>
              ARMAYA: WHERE LUXURY DINING LEAVE A LASTING IMPRESSION. <br />
              WHERE AUTHENTIC CUISINE MEETS AUTHENTIC TASTE. <br />
            </h2>
          </div>
          <div className="menu_listing row_am">
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>LARISSA</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className=" menuTextSlider"
                    {...DeliciousMenu}
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={500}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>Virgin Strawberry</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>French Mango</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Caramel Latte</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Cardamom Coffee</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Mochaccino</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={LarissaLogo} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/larissa">LARISSA</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Virgin Strawberry Daiquri</span>
                    </li>
                    <li>
                      <span>French Mango</span>
                    </li>
                    <li>
                      <span>Caramel Latte</span>
                    </li>
                    <li>
                      <span>Cardamom Coffee</span>
                    </li>
                    <li>
                      <span>Mochaccino</span>
                    </li>
                  </ul>
                </div>
                <Link to="/larissa" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>RIYASAT</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className="menuTextSlider"
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={500}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>Dal Dhokli</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Pav Bhaji</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Chole Kulche</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Cheese Pizza</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Patiyala Paneer</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={Riyasatlogo} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/riyasat">RIYASAT</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Dal Dhokli</span>
                    </li>
                    <li>
                      <span>Pav Bhaji</span>
                    </li>
                    <li>
                      <span>Chole Kulche</span>
                    </li>
                    <li>
                      <span>Cheese Pizza</span>
                    </li>
                    <li>
                      <span>Patiyala Paneer</span>
                    </li>
                  </ul>
                </div>
                <Link to="/riyasat" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>COURTYARD</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className="menuTextSlider"
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={500}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>5000 sqft Lawn</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Up to 400 Guests</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>5000 sqft Lawn</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Up to 400 Guests</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>5000 sqft Lawn</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={celebrationcourtyardlogo} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/celebration-courtyard">COURTYARD</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Stunning 5000 sqft Lawn</span>
                    </li>
                    <li>
                      <span>Welcomes Up to 400 Guests</span>
                    </li>
                    {/* <li>
                      <span>Rib-Eye Steak</span>
                    </li> */}
                    {/* <li>
                      <span>Lamb Chops</span>
                    </li> */}
                    {/* <li>
                      <span>Steak au Poivre</span>
                    </li> */}
                  </ul>
                </div>
                <Link to="/celebration-courtyard" className="btn btn_primary">
                  View More <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>BANQUET</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className="menuTextSlider"
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={500}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>5000 sqft Banquet</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Up to 400 Guests</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>5000 sqft Banquet</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Up to 400 Guests</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>5000 sqft Banquet</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={starlightballroomlogo} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/starlight-ballroom">BANQUET</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Stunning 5000 sqft Banquet</span>
                    </li>
                    <li>
                      <span>Welcomes Up to 400 Guests</span>
                    </li>
                    {/* <li>
                      <span>Triple-Cooked Fries</span>
                    </li> */}
                    {/* <li>
                      <span>Rib-Eye Steak</span>
                    </li> */}
                    {/* <li>
                      <span>Lamb Chops</span>
                    </li> */}
                  </ul>
                </div>
                <Link to="/starlight-ballroom" className="btn btn_primary">
                  View More <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="video_wraper">
          <div className="action_box" style={{ backgroundImage: `url(${BG})` }}>
            <div
              className="play_vtn d-flex justify-content-center align-items-center"
              onClick={() => setytShow(true)}
            >
              <div className="round_anim">
                <img src={videoimg} alt="img" />
              </div>
              <div className="action_btn">
                <Link
                  to="#"
                  className="popup-youtube play-button"
                  data-url="https://youtu.be/eUhHN8JnNeM"
                  data-toggle="modal"
                  data-target="#myModal"
                  title="XJj2PbenIsU"
                >
                  <img src={playimg} alt="img" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {ytshow && (
        <div
          className="modal fade youtube-video show"
          id="myModal"
          tabIndex={-1}
          role="dialog"
          style={{ paddingRight: 17, display: "block" }}
          aria-modal="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytShow(false)}>
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytShow(false)}
              >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    title="yt-video"
                    width={640}
                    height={360}
                    allowFullScreen={true}
                    src="https://www.youtube.com/embed/eUhHN8JnNeM"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Deliciousmenu