import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main'
import AboutImg from '../assets/images/abtbanshape.png'
import Icon01 from '../assets/images/title_icon.png'
import abt01 from '../assets/images/abtban_01_larissa.png'
import abt02 from '../assets/images/abtban_02_larissa.png'
import abt03 from '../assets/images/abtban_03_larissa.png'
import abt04 from '../assets/images/abtban_04_larissa.png'
import abt05 from '../assets/images/abtban_05_larissa.png'
import Reservation from '../components/MainReserv/ReservTwo';
import galleryone from '../assets/images/larissa-pg-1.webp'
import gallerytwo from '../assets/images/larissa-pg-2.webp'
import gallerythree from '../assets/images/larissa-pg-3.webp'
import galleryfour from '../assets/images/larissa-pg-4.webp'
import galleryfive from '../assets/images/larissa-pg-5.webp'
import gallerysix from '../assets/images/larissa-pg-6.webp'
import galleryseven from '../assets/images/larissa-pg-7.webp'
import galleryeight from '../assets/images/larissa-pg-8.webp'
import gallerynine from '../assets/images/larissa-pg-9.webp'
import galleryten from '../assets/images/larissa-pg-10.webp'
import galleryeleven from '../assets/images/larissa-pg-11.webp'
import gallerytwelve from '../assets/images/larissa-pg-12.webp'
import gallery13 from '../assets/images/larissa-pg-13.webp'
import gallery14 from '../assets/images/larissa-pg-14.webp'
import gallery15 from '../assets/images/larissa-pg-15.webp'
import gallery16 from '../assets/images/larissa-pg-16.webp'
import gallery17 from '../assets/images/larissa-pg-17.webp'
import gallery18 from '../assets/images/larissa-pg-18.webp'
import gallery19 from '../assets/images/larissa-pg-19.webp'
import gallery20 from '../assets/images/larissa-pg-20.webp'
import gallery21 from '../assets/images/larissa-pg-21.webp'
import gallery22 from '../assets/images/larissa-pg-22.webp'
import gallery23 from '../assets/images/larissa-pg-23.webp'
import gallery24 from '../assets/images/larissa-pg-24.webp'
import gallery25 from '../assets/images/larissa-pg-25.webp'
import gallery26 from '../assets/images/larissa-pg-26.webp'
import gallery27 from '../assets/images/larissa-pg-27.webp'
import gallery28 from '../assets/images/larissa-pg-28.webp'
import gallery29 from '../assets/images/larissa-pg-29.webp'
import gallery30 from '../assets/images/larissa-pg-30.webp'
import gallery31 from '../assets/images/larissa-pg-31.webp'
import gallery32 from '../assets/images/larissa-pg-32.webp'







import Aos from 'aos'
import TextFlow from '../components/MenAbout/TextFlow'
import FsLightbox from 'fslightbox-react';
import { Link } from 'react-router-dom';

function Larissa() {

    useEffect(() => {
      window.scrollTo(0,0);
    }, []);

    return (
        <div className='page_wrapper'>
          <Header />
          <>
      <section className="abt_banner">
        <div className="right_shape position-absolute">
          <img src={AboutImg} alt="img" />
        </div>
        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
            Larissa - Global Cuisine{" "}
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
          Explore the Global Culinary Journey at Larissa: Every Bite Tells a Story
          </h2>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          
          Immerse Yourself in an Exquisite Fusion of Flavors from Across Continents
          
          </p>
        </div>
        <div className="abt_img">
          <div className="img abt-img-res" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt01} alt="img" />
          </div>
          <div className="img abt-img-res" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt02} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={abt03} alt="img" />
          </div>
          <div className="img abt-img-res" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt04} alt="img" />
          </div>
          <div className="img abt-img-res" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt05} alt="img" />
          </div>
        </div>
      </section>
    </>
          <TextFlow />

          <section className="row_am offer_menu_section">
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="sub_text">Our menu</span>
            <h2>delicious tasty dishes.</h2>
          </div>
          </div>
          </section>


          <Menu />
          <div className="text-center" style={{margin: '30px'}}>
          <Link to="https://www.zomato.com/gandhidham/armaya-larissa-gandhidham-locality/order" className="btn btn_primary">Order From Larissa</Link>
          </div>
          <Reservation />
          <Footer />

          </div>
  )
}

function Menu() {

    const [images, setImages] = useState([
      galleryone,
      gallerytwo,
      gallerythree,
      galleryfour,
      galleryfive,
      gallerysix,
      galleryseven,
      galleryeight,
      gallerynine,
      galleryten,
      galleryeleven,
      gallerytwelve,
      gallery13,
      gallery14,
      gallery15,
      gallery16,
      gallery17,
      gallery18,
      gallery19,
      gallery20,
      gallery21,
      gallery22,
      gallery23,
      gallery24,
      gallery25,
      gallery26,
      gallery27,
      gallery28,
      gallery29,
      gallery30,
      gallery31,
      gallery32
  
    ])
  
    const [img, setImg] = useState()
    const [img1, setImg1] = useState()
    const [img2, setImg2] = useState()
    const [img3, setImg3] = useState()
    const [img4, setImg4] = useState()
    const [img5, setImg5] = useState()
    const [img6, setImg6] = useState()
    const [img7, setImg7] = useState()
    const [img8, setImg8] = useState()
    const [img9, setImg9] = useState()
    const [img10, setImg10] = useState()
    const [img11, setImg11] = useState()
  
    const [toggler, setToggler] = useState(false);
  
    const [activeImage, setActiveImage] = useState(1)
  
  
  
    return (
      <>
        <section className="row_am gallery_section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryone} alt="img" />
                  <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(1) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallerytwo} alt="img" />
                  <Link to="#" onClick={() => setImg1(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(2) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallerythree} alt="img" />
                  <Link to="#" onClick={() => setImg2(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(3) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryfour} alt="img" />
                  <Link to="#" onClick={() => setImg3(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(4) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryfive} alt="img" />
                  <Link to="#" onClick={() => setImg4(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(5) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallerysix} alt="img" />
                  <Link to="#" onClick={() => setImg5(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(6) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryseven} alt="img" />
                  <Link to="#" onClick={() => setImg6(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(7) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryeight} alt="img" />
                  <Link to="#" onClick={() => setImg7(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(8) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallerynine} alt="img" />
                  <Link to="#" onClick={() => setImg8(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(9) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryten} alt="img" />
                  <Link to="#" onClick={() => setImg9(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(10) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={galleryeleven} alt="img" />
                  <Link to="#" onClick={() => setImg10(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(11) }}>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallerytwelve} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(12) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery13} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(13) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery14} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(14) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery15} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(15) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery16} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(16) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery17} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(17) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery18} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(18) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery19} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(19) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery20} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(20) }}>
                  </Link>
                </div>
              </div>



              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery21} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(21) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery22} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(22) }}>
                  </Link>
                </div>
              </div>



              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery23} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(23) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery24} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(24) }}>
                  </Link>
                </div>
              </div>




              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery25} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(25) }}>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery26} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(26) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery27} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(27) }}>
                  </Link>
                </div>
              </div>




              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery28} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(28) }}>
                  </Link>
                </div>
              </div>





              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery29} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(29) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery30} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(30) }}>
                  </Link>
                </div>
              </div>



              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery31} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(31) }}>
                  </Link>
                </div>
              </div>


              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                  <img className="img-fluid" src={gallery32} alt="img" />
                  <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                    <i className="icofont-ui-zoom-in" />
                  </Link>
                  <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(32) }}>
                  </Link>
                </div>
              </div>

              





            </div>
          </div>
        </section>
        <FsLightbox toggler={toggler} sources={images} slide={activeImage}   />
      </>
    )
  }

export default Larissa;