import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Calender from '../../assets/images/calendar.png'
import axios from 'axios';
import { formatDate, formatTime } from '../../utils';

function ReservTwo() {
  const name = useRef(null);
  const person = useRef(null);
  const mobileNo = useRef(null);
  const message = useRef(null);
  const date = useRef(null);
  const time = useRef(null);
  const restaurant = useRef(null);
  
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    try {
    event.preventDefault();
    setLoading(true);
    const res = await axios.post("/.netlify/functions/submitForm", {
      name: name.current.value,
      mobileNo: mobileNo.current.value,
      person: person.current.value,
      message: message.current.value,
      date: date.current.value,
      time: time.current.value,
      restaurant: restaurant.current.value,
      formType: 'RESERVATION'

    });
    const response = await res.data;
    setResponse(response);
    setLoading(false);
  } catch(err){
    setLoading(false);
    setResponse('An unexpected Error Occurred');
  }
  };
  return (
    <>
      <section className="bredcrumb_section reservationpage_bg">
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="icon aos-init aos-animate" data-aos="fade-down" data-aos-duration={1500} >
              <img src={Calender} alt="img" />
            </span>
            <span className="sub_text">Online Reservation</span>
            <h2>Plot No. 18, 19, 34 & 35, Ward No.5B,
                Adipur, Gandhidham, Kutch, Gujarat 370205 <br /></h2>
          </div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
              <div className="fade booking_model show" id="bookingForm_model" tabIndex={-1} role="dialog" aria-labelledby="bookingForm_modelTitle" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header text-center border-0 d-block p-0">
                      <h2 className="mb-2">Book your delicious table now</h2>
                      <p>
                        Booking request <Link to="tel:+919512341011">+91-95123-41011</Link>{" "}
                        or fill out the order form
                      </p>
                    </div>
                    <div className="modal-body">
                      <div className="booking_form">
                        <form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input type="text" ref={name} className="form-control" placeholder="Your Name" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input type="text" ref={mobileNo} className="form-control" placeholder="Phone Number" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <span className="icon">
                                  <i className="icofont-user-alt-7" />
                                </span>
                                <input type="number" ref={person} style={{paddingLeft: '35px'}} defaultValue={2} className="form-control has_icon" placeholder="2 Person" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <span className="icon">
                                  <i className="icofont-calendar" />
                                </span>
                                <input type="date" ref={date} defaultValue={formatDate(new Date())} style={{paddingLeft: '35px'}} className="form-control  flex-row-reverse" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <span className="icon">
                                  <i className="icofont-clock-time" />
                                </span>
                                <input type="time" ref={time} defaultValue={formatTime(new Date())} style={{paddingLeft: '35px !important'}} className="form-control has_icon  flex-row-reverse" />
                              </div>
                            </div>
                            <div className="col-md-12">
                        <div className="form-group">
                        <span className="icon">
                            <i className="icofont-restaurant" />
                          </span>
                                <select style={{ paddingLeft: '35px' }} defaultValue={'LARISSA'} ref={restaurant} className="form-control has_icon  flex-row-reverse" >
                                  <option value={'LARISSA'} >Larissa</option>
                                  <option value={'RIYASAT'} >Riyasat</option>
                                  <option value={'CELEBRATION_COURTYARD'} >Celebration Courtyard</option>
                                  <option value={'STARLIGHT_BALLROOM'} >Starlight Ballroom</option>
                                </select>
                        </div>
                      </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea ref={message} className="form-control" placeholder="Message" defaultValue={""} />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button className="btn btn_primary w-100" disabled={loading} onClick={handleSubmit}>
                                book a table
                              </button>
                              {response && <div className="px-2 py-2 mt-5 col-md-12" style={{
                                background: "var(--primary-red)", borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between'
                              }}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1" />
          </div>
        </div>
      </section>
    </>
  )
}

export default ReservTwo